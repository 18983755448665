





























import {Prop, Vue, Component} from 'vue-property-decorator';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import moment, {Moment} from 'moment';
import {WEEKDAYS} from '@/Constants';

@Component({
  components: {
    CalendarEventTile: () => import(
        '@/components/shared/CalendarEventTile.component.vue'),
  },
})
export default class CalendarViewWeekComponent extends Vue {

  @Prop({default: () => []})
  public events!: Array<{ job: Job, userData: JobUserDataInterface[] }>;

  @Prop({default: () => moment()})
  public startDate!: Moment;

  /**
   * Splits the events array in an array of arrays. First the weekdays, then the events happening at that day
   */
  private get weekEvents() {
    const weekEvents: Array<Array<{ job: Job, userData: JobUserDataInterface[] }>> = [];
    this.week.forEach((day, index) =>
      weekEvents[index] = this.events.filter((event) =>
          this.isDateAtDay(event.job.cleanTimeOccurrence.start, day)
          &&
          moment.utc(event.job.cleanTimeOccurrence.start).isSameOrAfter(moment.utc(event.job.queryDate))),
    );
    return weekEvents;
  }

  /**
   * Getter to get the weekdays by just having the start date of the week
   */
  private get week() {
    const week: Moment[] = [];
    for (let i = 0; i < 7; i++) {
      week.push(this.startDate.clone().add(i, 'days'));
    }
    return week;
  }

  /**
   *
   * @param weekDay
   * @private
   */
  private getEvents(weekDay: number): Array<{ job: Job, userData: JobUserDataInterface[] } | undefined> {
    let eventsPerDay = 6;
    this.weekEvents.forEach((events) => {
      if (events.length > eventsPerDay) {
        eventsPerDay = events.length;
      }
    });
    const calendarEvents: Array<{ job: Job, userData: JobUserDataInterface[] } | undefined> = this.weekEvents[weekDay];
    if (calendarEvents.length < eventsPerDay) {
      const eventsToAdd = eventsPerDay - calendarEvents.length;
      for (let i = 0; i < eventsToAdd; i++) {
        calendarEvents.push(undefined);
      }
    }
    return calendarEvents;
  }

  /**
   * Helper function to check if a date is at a specific Day
   * @param date
   * @param reference
   * @private
   */
  private isDateAtDay(date: string | Moment, reference: Moment): boolean {
    // The end of the day before needs to be before the given date...
    return reference.clone().subtract(1, 'days').endOf('day').isBefore(date) &&
        // and the start of the day after needs to be after the given date
        reference.clone().add(1, 'days').startOf('day').isAfter(date);
  }

  /**
   * Formats a moment to the format needed in the header row(eg. Mo, 31.1.)
   * @param day
   */
  private formatWeekDay(day: Moment) {
    return this.$t(`GENERAL.DAYS.${WEEKDAYS[(day.day() + 6) % 7].toUpperCase()}`).toString().slice(0, 2) + ', '
        + day.format('DD.MM');
  }

  private emitOnDateClick(day: Moment) {
    this.$emit('onDateClick', day);
  }

  private showEventDetails(event: any) {
    this.$emit('showDetails', event);
  }
}
